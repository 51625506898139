
import { Vue, Options } from 'vue-class-component';
import { BaseTextInput, BaseCard, BaseButton, ClearButton } from '@/lib/components';
import { OrganisationService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    ClearButton,
    BaseButton,
    BaseCard,
    BaseTextInput
  }
})
export default class EscalationEmailsSettingPage extends Vue {
  loading = false;
  errors: { [key: string]: Array<string> } = {};
  escalationEmails: Array<string> = [''];
  orgService: OrganisationService = new OrganisationService();
  notificationStore = useNotificationStore();

  get organisationId() {
    return this.$route.params.organisationId;
  }

  async mounted() {
    try {
      this.$watch('organisationId', async (value) => {
        const orgUnitData = await this.orgService.fetch(value);
        this.escalationEmails = orgUnitData.escalation_emails ? orgUnitData.escalation_emails : [];
      });
      const orgData = await this.orgService.fetch(this.organisationId);
      this.escalationEmails = orgData.escalation_emails ? orgData.escalation_emails : [];
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.escalation-email.fetch-error'),
        label: e.message
      });
    }
  }

  addNewEscalationEmailAddress() {
    const lastEmail = this.escalationEmails[this.escalationEmails.length - 1];
    if (this.escalationEmails.length === 0 || lastEmail) {
      // We don't want to add any more emails if the last one is empty
      this.escalationEmails.push('');
    }
  }

  removeEmail(index: number) {
    this.escalationEmails = this.escalationEmails.filter((el: string, i: number) => i !== index);
  }

  cancel() {
    this.$router.back();
  }

  async saveChanges() {
    this.errors = {};
    let escalationEmails: Array<string> | null = this.escalationEmails.filter((email) => email);
    if (escalationEmails.length === 0) {
      escalationEmails = [];
    }

    try {
      await this.orgService.update(this.organisationId, {
        escalation_emails: escalationEmails
      });
      await this.notificationStore.addSuccessNotification({
        title: this.$t('custom.uhb.escalation-email.update-success')
      });
    } catch (error) {
      if (error.response.status === 422) {
        this.errors = error.response.data.errors;
      } else {
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.escalation-email.update-error'),
          label: error.message
        });
      }
    }
  }

  getError(index: number) {
    const error = this.errors[`escalation_emails.${index}`];
    return error ? this.$t('platform.error.email-error') : '';
  }
}
